import React from 'react';
import './navbar.css';
import { Link } from 'gatsby';

import { CgMenu, CgClose, CgPhone } from 'react-icons/cg';
import logo from "../../images/danas-plat-och-smide.png"; //optosweden logo


class MyNavbar extends React.Component {
    constructor(){
        super();
        this.state={
            navbar_active: false,
            mobile_menu: false,
        }

        this.mobile_menu = this.mobile_menu.bind(this);
        this.close_menu = this.close_menu.bind(this);
        this.navbar_active = this.navbar_active.bind(this);
    }

    //open and close mobile menu
    mobile_menu(event){
        event.preventDefault();
        if(this.state.mobile_menu === false){
            this.setState({mobile_menu: true})
        }

        if(this.state.mobile_menu === true){
            this.setState({mobile_menu: false})
        }
    }

    //close mobile menu
    close_menu(event){
        event.preventDefault();
        this.setState({mobile_menu: false});
    }

     //Change nav background if scrolled
    navbar_active(){
        if (window.scrollY >= 80) {
            this.setState({navbar_active: true});
        }else {
            this.setState({navbar_active: false});
        }
    }

    render(){
        //Event listener for scrolling
        if (typeof window !== `undefined`) {
            window.addEventListener('scroll', this.navbar_active)
        }

        return(
            <>
            <nav className='dana_nav'>
                <div className="container mx-auto px-2 ">
                    <main className="mynav">
                        <Link to="/">
                            <img className="logo" src={logo} alt="Danas Plåt och Smide AB" /> 
                        </Link>
                
                        <div className='mobile_menu_icon' onClick={this.mobile_menu} onKeyDown={this.mobile_menu} role="button" tabIndex={0}>
                            {this.state.mobile_menu ? <CgClose />  : <CgMenu /> } 
                        </div>


                        <div className={this.state.mobile_menu ? "linksList active"  : "linksList"}>
                            <Link to='/' className={this.props.active_page === 'hem'? "active_nav_link" : "nav_link"}>
                                <span>hem</span>
                            </Link>

                            <Link to='/tjanster' className={this.props.active_page === 'tjanster'? "active_nav_link" : "nav_link"} >
                                <span>tjänster</span>
                            </Link>
                            
                            <Link to='/om-oss' className={this.props.active_page === 'om-oss'? "active_nav_link" : "nav_link"} >
                                <span>om oss</span>
                            </Link>

                            <Link to='/kontakt' className={this.props.active_page === 'kontakt'? "active_nav_link" : "nav_link"} >
                                <span>kontakt</span>
                            </Link>  

                            <div className="navLink_phone"><CgPhone/> 08-550 175 11</div>
                        </div>
                    </main>
                </div>
            </nav>
            <div style={{height: '80px'}}></div>
            </>
        );
    }
}
export default MyNavbar;